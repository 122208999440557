import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBFormuBankaV2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBFormuBankaV2_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  sbFirmaTuruTRENList: any[];
  sbFirmaTuruTRENList_dummy: any[];
  sbProjeTuruTRENList: any[];
  sbProjeTuruTRENList_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  getBasvuruFormuById: any[];
  getBasvuruFormuById_dummy: any[];
  spGetListKullanimByBFId: any[];
  spGetListKullanimByBFId_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  GoBack: any;
  GoBack_dummy: any;
  sbBolgeTRENList: any[];
  sbBolgeTRENList_dummy: any[];
  sbKullanimTuruTRENListByRefKod: any[];
  sbKullanimTuruTRENListByRefKod_dummy: any[];
  spMecralarSelectedColorR: any[];
  spMecralarSelectedColorR_dummy: any[];
  spMecralarSelectedColorO: any[];
  spMecralarSelectedColorO_dummy: any[];
  spMecralarSelectedColorD: any[];
  spMecralarSelectedColorD_dummy: any[];
  spMecralarSelectedColorT: any[];
  spMecralarSelectedColorT_dummy: any[];
  spMecralarSelectedColorV: any[];
  spMecralarSelectedColorV_dummy: any[];
  getSelectedRowId: any[];
  getSelectedRowId_dummy: any[];
  spGetMecraSecildi: any[];
  spGetMecraSecildi_dummy: any[];
  spGetEserTanim: any[];
  spGetEserTanim_dummy: any[];
  setKullanimSureleriInsert: any[];
  setKullanimSureleriInsert_dummy: any[];
  delKullanimSureleriByIds: number;
  delKullanimSureleriByIds_dummy: number;
  spBasvuruFormuInsert: any[];
  spBasvuruFormuInsert_dummy: any[];
  spUpdateServisUcret: any[];
  spUpdateServisUcret_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp665291Visible: "visible" | "hidden";
  isComp496054Visible: "visible" | "hidden";
  isComp108743Visible: "visible" | "hidden";
  isComp629685Visible: "visible" | "hidden";
  isComp136378Visible: "visible" | "hidden";
  isComp315959Visible: "visible" | "hidden";
  isComp248524Visible: "visible" | "hidden";
  isComp915207Visible: "visible" | "hidden";
  isComp123993Visible: "visible" | "hidden";
  isComp714077Visible: "visible" | "hidden";
  isComp633827Visible: "visible" | "hidden";
  isComp439359Visible: "visible" | "hidden";
  isComp448633Visible: "visible" | "hidden";
  isComp283848Visible: "visible" | "hidden";
  isComp367103Visible: "visible" | "hidden";
  isComp496960Visible: "visible" | "hidden";
  isComp301339Visible: "visible" | "hidden";
  isComp654727Visible: "visible" | "hidden";
  isComp772542Visible: "visible" | "hidden";
  isComp597446Visible: "visible" | "hidden";
  isComp304820Visible: "visible" | "hidden";
  isComp541034Visible: "visible" | "hidden";
  isComp298517Visible: "visible" | "hidden";
  isComp297583Visible: "visible" | "hidden";
  isComp236080Visible: "visible" | "hidden";
  isComp861186Visible: "visible" | "hidden";
  isComp440139Visible: "visible" | "hidden";
  isComp441701Visible: "visible" | "hidden";
  isComp99417Visible: "visible" | "hidden";
  isComp417431Visible: "visible" | "hidden";
  isComp726503Visible: "visible" | "hidden";
  isComp873862Visible: "visible" | "hidden";
  isComp976461Visible: "visible" | "hidden";
  isComp147716Visible: "visible" | "hidden";
  isComp670571Visible: "visible" | "hidden";
  isComp778304Visible: "visible" | "hidden";
  isComp458889Visible: "visible" | "hidden";
  isComp814839Visible: "visible" | "hidden";
  isComp203519Visible: "visible" | "hidden";
  isComp169377Visible: "visible" | "hidden";
  isComp313471Visible: "visible" | "hidden";
  isComp399400Visible: "visible" | "hidden";
  isComp449295Visible: "visible" | "hidden";
}

export class BFormuBankaV2_ScreenBase extends React.PureComponent<IBFormuBankaV2_ScreenProps, any> {
  bformubankav2_779264_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_526476_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_622154_value_kuikaDateRef: React.RefObject<any>;
  bformubankav2_489574_value_kuikaMultiSelectRef: React.RefObject<any>;
  bformubankav2_501496_value_kuikaDateRef: React.RefObject<any>;
  bformubankav2_353342_value_kuikaDatePickerRef: React.RefObject<any>;
  bformubankav2_306900_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_299989_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_632783_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_428431_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_693646_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_55923_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformubankav2_38854_value_kuikaMultiSelectRef: React.RefObject<any>;
  bformubankav2_658984_value_kuikaFlexGridRef: React.RefObject<any>;
  bformubankav2_673720_value_kuikaFlexGridRef: React.RefObject<any>;
  bformubankav2_381512_value_kuikaFlexGridRef: React.RefObject<any>;
  bformubankav2_953668_value_kuikaFlexGridRef: React.RefObject<any>;
  bformubankav2_577170_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "92b89591-9413-4105-b9c5-b1e7be5964a0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 861186, PropertyName: "value", Value: "MÜZIK BANKASI (MAMA) BAŞVURU / KULLANIM FORMU" },
        { Id: 440139, PropertyName: "value", Value: "MUSIC LIBRARIES (MAMA) LICENSE REQUEST FORM" },
        { Id: 441701, PropertyName: "value", Value: "Müzik Bankası (BABA) Başvuru / Kullanım Formu" },
        { Id: 346979, PropertyName: "value", Value: "Başvuruyu Yapan Kişi" },
        { Id: 928244, PropertyName: "value", Value: "Name Of Person Making The Request" },
        { Id: 750474, PropertyName: "placeholder", Value: "..." },
        { Id: 213845, PropertyName: "value", Value: "İletişim Bilgileri (E-mail)" },
        { Id: 825716, PropertyName: "value", Value: "Contact E-mail" },
        { Id: 192848, PropertyName: "placeholder", Value: "..." },
        { Id: 186660, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 841154, PropertyName: "value", Value: "Telephone Number" },
        { Id: 390390, PropertyName: "placeholder", Value: "..." },
        { Id: 176074, PropertyName: "value", Value: "Başvuruyu Yapan Firma" },
        { Id: 455728, PropertyName: "value", Value: "Company Making The Request" },
        { Id: 366151, PropertyName: "placeholder", Value: "..." },
        { Id: 305173, PropertyName: "value", Value: "Firma Türü" },
        { Id: 308559, PropertyName: "value", Value: "Type Of Company" },
        { Id: 779264, PropertyName: "placeholder", Value: "..." },
        { Id: 596115, PropertyName: "value", Value: "İş Yapılacak Firma" },
        { Id: 852622, PropertyName: "value", Value: "..." },
        { Id: 654168, PropertyName: "placeholder", Value: "..." },
        { Id: 739201, PropertyName: "value", Value: "Projenin Adı" },
        { Id: 833995, PropertyName: "value", Value: "Name Of Project" },
        { Id: 757838, PropertyName: "placeholder", Value: "..." },
        { Id: 215415, PropertyName: "value", Value: "Projenin Türü" },
        { Id: 461376, PropertyName: "value", Value: "Type Of Project" },
        { Id: 526476, PropertyName: "placeholder", Value: "..." },
        { Id: 356360, PropertyName: "value", Value: "Sezon" },
        { Id: 732897, PropertyName: "value", Value: "Season" },
        { Id: 633679, PropertyName: "placeholder", Value: "..." },
        { Id: 119796, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 724904, PropertyName: "value", Value: "Episode Number" },
        { Id: 245794, PropertyName: "placeholder", Value: "..." },
        { Id: 520814, PropertyName: "value", Value: "Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 503023, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 730982, PropertyName: "placeholder", Value: "..." },
        { Id: 597480, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 793936, PropertyName: "value", Value: "Release Date" },
        { Id: 622154, PropertyName: "placeholder", Value: "..." },
        { Id: 623167, PropertyName: "value", Value: "Ana Film Sayısı" },
        { Id: 86175, PropertyName: "value", Value: "Number Of Main Commercial Films" },
        { Id: 877384, PropertyName: "placeholder", Value: "..." },
        { Id: 974788, PropertyName: "value", Value: "Versiyonları" },
        { Id: 664492, PropertyName: "value", Value: "Number Of Main Commercial Films" },
        { Id: 628827, PropertyName: "placeholder", Value: "..." },
        { Id: 630606, PropertyName: "value", Value: "Film Bütçesi" },
        { Id: 214275, PropertyName: "value", Value: "Film Budget" },
        { Id: 918664, PropertyName: "placeholder", Value: "..." },
        { Id: 86138, PropertyName: "value", Value: "Kullanım Alanı" },
        { Id: 178370, PropertyName: "value", Value: "Area Of Usage" },
        { Id: 489574, PropertyName: "placeholder", Value: "..." },
        { Id: 88093, PropertyName: "value", Value: "Oyun Bütçesi" },
        { Id: 716500, PropertyName: "value", Value: "Game Budget" },
        { Id: 980108, PropertyName: "placeholder", Value: "..." },
        { Id: 656035, PropertyName: "value", Value: "Tiyatro Salonu Adı" },
        { Id: 827056, PropertyName: "value", Value: "Name of The Theatre" },
        { Id: 98955, PropertyName: "placeholder", Value: "..." },
        { Id: 202626, PropertyName: "value", Value: "Gösterim Tarihi" },
        { Id: 400544, PropertyName: "value", Value: "Release Date" },
        { Id: 501496, PropertyName: "placeholder", Value: "..." },
        { Id: 936874, PropertyName: "value", Value: "Teslimat / Deadline Bilgisi" },
        { Id: 572320, PropertyName: "value", Value: "Date of deadline" },
        { Id: 353342, PropertyName: "placeholder", Value: "..." },
        { Id: 799785, PropertyName: "value", Value: "Acil ise ayrıca tıklayın" },
        { Id: 819136, PropertyName: "value", Value: "Please mark if urgent" },
        { Id: 526789, PropertyName: "value", Value: "Sizin için müzik seçmemizi istiyor musunuz?" },
        { Id: 992647, PropertyName: "value", Value: "Would you like a music search?" },
        {
          Id: 399774,
          PropertyName: "value",
          Value:
            "Sizin için müzik seçmemizi istiyorsanız; Lütfen aradığınız müziği bize detaylı anlatın (Vokalli, vokalsiz, enstrüman tercihi). Örnek müzik paylaşmak isterseniz, YouTube linki de yapıştırabilirsiniz"
        },
        {
          Id: 575791,
          PropertyName: "value",
          Value:
            "If you would like a music selection; Please describe the type of music you’re looking for (Vocal, instrumental, choice of instruments etc). Feel free to paste a YouTube link."
        },
        { Id: 852944, PropertyName: "placeholder", Value: "..." },
        { Id: 417431, PropertyName: "children", Value: "Evet" },
        {
          Id: 825120,
          PropertyName: "value",
          Value: 'Kullanım detaylarını girebilmek için; Lütfen yukarıdan "Proje Türü" seçin.'
        },
        {
          Id: 353000,
          PropertyName: "value",
          Value: "Please select “Type of Project” in order to enter usage details."
        },
        {
          Id: 140911,
          PropertyName: "value",
          Value:
            "Projenizin detayları netleşmiş ise lütfen aşağıda bulunan tüm alanları eksiksiz seçip, bağlantılı müzik bilgisini girip “EKLE” butonuna basmayı unutmayın. Aksi takdirde sistem kayıt etmeyecektir."
        },
        {
          Id: 340387,
          PropertyName: "value",
          Value:
            "If you know the details of your project, please select all the details below and then remember to click on “ADD”, otherwise the system won’t save it."
        },
        { Id: 917297, PropertyName: "value", Value: "Lisans Bölgesi" },
        { Id: 92955, PropertyName: "value", Value: "/ Territory" },
        { Id: 306900, PropertyName: "placeholder", Value: "..." },
        { Id: 283848, PropertyName: "value", Value: "Kullanım Türü" },
        { Id: 367103, PropertyName: "value", Value: "Versiyon" },
        { Id: 637820, PropertyName: "value", Value: "/ Usage Type" },
        { Id: 299989, PropertyName: "placeholder", Value: "..." },
        { Id: 154358, PropertyName: "value", Value: "Mecralar" },
        { Id: 362725, PropertyName: "value", Value: "/ Media" },
        { Id: 976461, PropertyName: "value", Value: "( Lütfen aşağıdan seçin. )" },
        { Id: 982559, PropertyName: "value", Value: "TV" },
        { Id: 516242, PropertyName: "value", Value: "/ TV" },
        { Id: 632783, PropertyName: "placeholder", Value: "..." },
        { Id: 729479, PropertyName: "value", Value: "VOD" },
        { Id: 915961, PropertyName: "value", Value: "/ VOD" },
        { Id: 428431, PropertyName: "placeholder", Value: "..." },
        { Id: 17292, PropertyName: "value", Value: "RADYO" },
        { Id: 270468, PropertyName: "value", Value: "/ RADIO" },
        { Id: 693646, PropertyName: "placeholder", Value: "..." },
        { Id: 513139, PropertyName: "value", Value: "ONLINE" },
        { Id: 828837, PropertyName: "value", Value: "ONLINE" },
        { Id: 55923, PropertyName: "placeholder", Value: "..." },
        { Id: 171097, PropertyName: "value", Value: "DİĞER" },
        { Id: 61957, PropertyName: "value", Value: "OTHER" },
        {
          Id: 38854,
          PropertyName: "placeholder",
          Value:
            "Diğer Mecra Alanlarını tek tek tıklayarak seçebilirsiniz... / Individually click to select the areas of Other Media"
        },
        { Id: 858296, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 670571, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 431566, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 778304, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 54613, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 458889, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 544022, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 814839, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 304820, PropertyName: "value", Value: "İlgili Eser Adı ve Kodu" },
        { Id: 541034, PropertyName: "value", Value: "İstenilen Müzik Tarzı" },
        { Id: 186929, PropertyName: "value", Value: "Track Name & Code" },
        {
          Id: 315959,
          PropertyName: "placeholder",
          Value: "MUZ066_TK008_ISLAND_LOCALS_(A)_Anonymous_Muzikotek_Publishing"
        },
        { Id: 248524, PropertyName: "placeholder", Value: "Aradığınız müziğin detayını buraya yazın..." },
        {
          Id: 298517,
          PropertyName: "value",
          Value:
            "Lütfen aradığınız müziği bize detaylı anlatın (Vokalli, vokalsiz, enstrüman tercihi). Örnek müzik paylaşmak isterseniz, YouTube linki de yapıştırabilirsiniz"
        },
        {
          Id: 297583,
          PropertyName: "value",
          Value:
            "If you would like a music selection; Please describe the type of music you’re looking for (Vocal, instrumental, choice of instruments etc). Feel free to paste a YouTube link."
        },
        { Id: 122781, PropertyName: "value", Value: "Adet" },
        { Id: 633275, PropertyName: "value", Value: "Quantity" },
        { Id: 770932, PropertyName: "placeholder", Value: "0" },
        { Id: 344261, PropertyName: "value", Value: "Kullanım" },
        { Id: 531711, PropertyName: "value", Value: "Duration" },
        { Id: 707397, PropertyName: "placeholder", Value: "Dk/Min" },
        { Id: 444884, PropertyName: "value", Value: "Süresi" },
        { Id: 533932, PropertyName: "value", Value: "of Usage" },
        { Id: 968501, PropertyName: "placeholder", Value: "Sn/Sec" },
        { Id: 123993, PropertyName: "label", Value: "Ekle / Add" },
        { Id: 415808, PropertyName: "value", Value: "Eser Adı ve Kodu" },
        { Id: 214865, PropertyName: "value", Value: "Track Name & Code" },
        { Id: 569809, PropertyName: "value", Value: "Adet" },
        { Id: 318795, PropertyName: "value", Value: "Quantity" },
        { Id: 237488, PropertyName: "value", Value: "Dakika" },
        { Id: 744951, PropertyName: "value", Value: "Min." },
        { Id: 851322, PropertyName: "value", Value: "Saniye" },
        { Id: 896841, PropertyName: "value", Value: "Sec." },
        { Id: 577170, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 211540, PropertyName: "title", Value: "Bilgi" },
        { Id: 81535, PropertyName: "value", Value: "[datafield:bolge]" },
        { Id: 590946, PropertyName: "value", Value: "[datafield:mecras]" },
        { Id: 284897, PropertyName: "value", Value: "[datafield:kullanimturu]" },
        { Id: 315736, PropertyName: "value", Value: "[datafield:muzikkod]" },
        { Id: 871564, PropertyName: "value", Value: "[datafield:suresaat]" },
        { Id: 922552, PropertyName: "value", Value: "[datafield:suredakika]" },
        { Id: 962051, PropertyName: "value", Value: "[datafield:suresaniye]" },
        { Id: 489554, PropertyName: "value", Value: "Ek Bilgi / Soru" },
        { Id: 669685, PropertyName: "value", Value: "Additional Info / Questions" },
        { Id: 409878, PropertyName: "placeholder", Value: "..." },
        { Id: 481235, PropertyName: "value", Value: "Not: ..." },
        { Id: 633827, PropertyName: "label", Value: "Vazgeç/Cancel" },
        { Id: 714077, PropertyName: "label", Value: "Başvuru Yap / Send" },
        { Id: 915207, PropertyName: "label", Value: "Güncelle" },
        { Id: 399400, PropertyName: "label", Value: "visibility screnn input <> info" },
        { Id: 449295, PropertyName: "label", Value: "visibility screnn input = ticket" },
        { Id: 448633, PropertyName: "label", Value: "set visibility screnn input <> info" },
        { Id: 22783, PropertyName: "label", Value: "set visibility screnn input = ticket" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bformubankav2_750474_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_192848_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_390390_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_366151_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_779264_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_654168_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_757838_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_526476_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_633679_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_245794_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_730982_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_622154_value_kuikaDateRef = React.createRef();
    this.bformubankav2_877384_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_628827_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_918664_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_489574_value_kuikaMultiSelectRef = React.createRef();
    this.bformubankav2_980108_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_98955_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_501496_value_kuikaDateRef = React.createRef();
    this.bformubankav2_353342_value_kuikaDatePickerRef = React.createRef();
    this.bformubankav2_306900_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_299989_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_632783_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_428431_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_693646_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_55923_value_kuikaSelectBoxRef = React.createRef();
    this.bformubankav2_38854_value_kuikaMultiSelectRef = React.createRef();
    this.bformubankav2_658984_value_kuikaFlexGridRef = React.createRef();
    this.bformubankav2_670571_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_673720_value_kuikaFlexGridRef = React.createRef();
    this.bformubankav2_778304_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_381512_value_kuikaFlexGridRef = React.createRef();
    this.bformubankav2_458889_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_953668_value_kuikaFlexGridRef = React.createRef();
    this.bformubankav2_814839_value_kuikaTextInputRef = React.createRef();
    this.bformubankav2_577170_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      getUserInfo: [],
      sbFirmaTuruTRENList: [],
      sbProjeTuruTRENList: [],
      sbMecraList: [],
      getBasvuruFormuById: [],
      spGetListKullanimByBFId: [],
      TriggerEvent: "",
      SetValueOf: "",
      ChangeVisibilityOf: "",
      GoBack: "",
      sbBolgeTRENList: [],
      sbKullanimTuruTRENListByRefKod: [],
      spMecralarSelectedColorR: [],
      spMecralarSelectedColorO: [],
      spMecralarSelectedColorD: [],
      spMecralarSelectedColorT: [],
      spMecralarSelectedColorV: [],
      getSelectedRowId: [],
      spGetMecraSecildi: [],
      spGetEserTanim: [],
      setKullanimSureleriInsert: [],
      delKullanimSureleriByIds: "",
      spBasvuruFormuInsert: [],
      spUpdateServisUcret: [],
      NAVIGATE: "",
      dataSource_489574: [
        { text: "App", key: "1" },
        { key: "2", text: "Perakende Oyun" },
        { key: "3", text: "Dijital Oyun" }
      ],
      isComp665291Visible: "hidden",
      isComp496054Visible: "hidden",
      isComp108743Visible: "hidden",
      isComp629685Visible: "hidden",
      isComp136378Visible: "hidden",
      isComp315959Visible: "visible",
      isComp248524Visible: "hidden",
      isComp915207Visible: "hidden",
      isComp123993Visible: "hidden",
      isComp714077Visible: "hidden",
      isComp633827Visible: "visible",
      isComp439359Visible: "visible",
      isComp448633Visible: "visible",
      isComp283848Visible: "visible",
      isComp367103Visible: "hidden",
      isComp496960Visible: "visible",
      isComp301339Visible: "hidden",
      isComp654727Visible: "hidden",
      isComp772542Visible: "hidden",
      isComp597446Visible: "hidden",
      isComp304820Visible: "visible",
      isComp541034Visible: "hidden",
      isComp298517Visible: "hidden",
      isComp297583Visible: "hidden",
      isComp236080Visible: "hidden",
      isComp861186Visible: "hidden",
      isComp440139Visible: "hidden",
      isComp441701Visible: "hidden",
      isComp99417Visible: "hidden",
      isComp417431Visible: "hidden",
      isComp726503Visible: "hidden",
      isComp873862Visible: "hidden",
      isComp976461Visible: "hidden",
      isComp147716Visible: "hidden",
      isComp670571Visible: "hidden",
      isComp778304Visible: "hidden",
      isComp458889Visible: "hidden",
      isComp814839Visible: "hidden",
      isComp203519Visible: "hidden",
      isComp169377Visible: "hidden",
      isComp313471Visible: "hidden",
      isComp399400Visible: "hidden",
      isComp449295Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bformubankav2", "");
      return;
    }

    if (true) {
      await this.BFormuBankaV2PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bformubankav2", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bformubankav2", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BFormuBankaV2PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      bformubankav2_366151_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuranSirketAd ?? undefined,
      bformubankav2_779264_value: this.state.getBasvuruFormuById?.at?.(0)?.firmaTuruID ?? undefined,
      bformubankav2_654168_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
      bformubankav2_757838_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformubankav2_526476_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
      bformubankav2_633679_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
      bformubankav2_245794_value: this.state.getBasvuruFormuById?.at?.(0)?.bolum ?? undefined,
      bformubankav2_730982_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinKanali ?? undefined,
      bformubankav2_622154_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi2 ?? undefined,
      bformubankav2_877384_value: this.state.getBasvuruFormuById?.at?.(0)?.filmSayisi2 ?? undefined,
      bformubankav2_628827_value: this.state.getBasvuruFormuById?.at?.(0)?.versiyon ?? undefined,
      bformubankav2_918664_value: this.state.getBasvuruFormuById?.at?.(0)?.filmButce ?? undefined,
      bformubankav2_489574_value: this.state.getBasvuruFormuById?.at?.(0)?.kullanimAlani ?? undefined,
      bformubankav2_980108_value: this.state.getBasvuruFormuById?.at?.(0)?.oyunButce ?? undefined,
      bformubankav2_98955_value: this.state.getBasvuruFormuById?.at?.(0)?.tiyatroSalonAd ?? undefined,
      bformubankav2_501496_value: this.state.getBasvuruFormuById?.at?.(0)?.gosterimTarihi ?? undefined,
      bformubankav2_353342_value: this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined,
      bformubankav2_269440_value: this.state.getBasvuruFormuById?.at?.(0)?.acil ?? undefined,
      bformubankav2_852944_value: this.state.getBasvuruFormuById?.at?.(0)?.briefAciklama ?? undefined,
      bformubankav2_417431_value: this.state.getBasvuruFormuById?.at?.(0)?.isMuzikSecimi ?? undefined,
      bformubankav2_306900_value: this.state.getBasvuruFormuById?.at?.(0)?.bolgeIDTEMP ?? undefined,
      bformubankav2_38854_value: this.state.getBasvuruFormuById?.at?.(0)?.kullanimAlani ?? undefined,
      bformubankav2_770932_value: 1,
      bformubankav2_707397_value: NaN,
      bformubankav2_968501_value: NaN,
      bformubankav2_409878_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BFormuBankaV2PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      BasvuruFormuID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuBankaV2/BFormuBankaV2PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.sbFirmaTuruTRENList = result?.data.sbFirmaTuruTRENList;

    formVars.bformubankav2_779264_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformubankav2_779264_options = stateVars.sbFirmaTuruTRENList;
    stateVars.sbProjeTuruTRENList = result?.data.sbProjeTuruTRENList;

    formVars.bformubankav2_526476_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformubankav2_526476_options = stateVars.sbProjeTuruTRENList;
    stateVars.sbMecraList = result?.data.sbMecraList;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.bformubankav2_366151_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );
    formVars.bformubankav2_779264_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformubankav2_779264_options = stateVars.sbFirmaTuruTRENList;
    formVars.bformubankav2_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );
    formVars.bformubankav2_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformubankav2_526476_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformubankav2_526476_options = stateVars.sbProjeTuruTRENList;
    formVars.bformubankav2_633679_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );
    formVars.bformubankav2_245794_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].bolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].bolum
        : null
    );
    formVars.bformubankav2_730982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinKanali
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinKanali
        : null
    );
    formVars.bformubankav2_622154_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi2
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi2
        : null
    );
    formVars.bformubankav2_877384_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi2
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi2
        : null
    );
    formVars.bformubankav2_628827_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyon
        : null
    );
    formVars.bformubankav2_918664_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmButce
        : null
    );
    formVars.bformubankav2_489574_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimAlani
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimAlani
        : null;
    formVars.bformubankav2_980108_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].oyunButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].oyunButce
        : null
    );
    formVars.bformubankav2_98955_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tiyatroSalonAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tiyatroSalonAd
        : null
    );
    formVars.bformubankav2_501496_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].gosterimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].gosterimTarihi
        : null
    );
    formVars.bformubankav2_353342_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].terminTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].terminTarihi
        : null
    );
    formVars.bformubankav2_353342_disablebeforedate = moment().format("YYYY-MM-DDTHH:mm:ss");
    formVars.bformubankav2_269440_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].acil
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].acil
        : null
    );
    formVars.bformubankav2_852944_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].briefAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].briefAciklama
        : null
    );
    formVars.bformubankav2_417431_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].isMuzikSecimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].isMuzikSecimi
        : null
    );
    formVars.bformubankav2_306900_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].bolgeIDTEMP
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].bolgeIDTEMP
        : null;
    formVars.bformubankav2_38854_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimAlani
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimAlani
        : null;
    formVars.bformubankav2_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );
    stateVars.spGetListKullanimByBFId = result?.data.spGetListKullanimByBFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    if (await this.BFormuBankaV2Component_526476_onChange()) return true;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_750474_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].adSoyadTEMP
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].adSoyadTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformubankav2_750474_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].fullName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].fullName
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_192848_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].eMailTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].eMailTEMP
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].eMailTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].eMailTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformubankav2_192848_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].userName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].userName
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_390390_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].gsmtemp
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].gsmtemp
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit7_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].gsmtemp
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].gsmtemp
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformubankav2_390390_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].gsm
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].gsm
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "03"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp665291Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "09"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp665291Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp496054Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "05"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp108743Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "02"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp629685Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "08"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp136378Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_968501_value", "string"),
      ReactSystemFunctions.convertToTypeByName("0", "number"),
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp315959Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp248524Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2PageInit15_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].isMuzikSecimi
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].isMuzikSecimi
            : null
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp315959Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].isMuzikSecimi
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].isMuzikSecimi
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp315959Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].isMuzikSecimi
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].isMuzikSecimi
            : null
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp248524Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp915207Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp123993Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp714077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp714077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp633827Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp915207Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp439359Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "info"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp448633Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp448633Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2PageInit27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2PageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp236080Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay, "D") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "00000000-0000-0000-0000-000000000001"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440139Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "00000000-0000-0000-0000-000000000001"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp441701Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "00000000-0000-0000-0000-000000000004"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformubankav2_366151_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );

    formVars.bformubankav2_779264_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null
    );

    stateVars.dataSource_779264 = this.state.sbFirmaTuruTRENList;
    stateVars.dataSource_779264 = this.state.sbFirmaTuruTRENList;
    formVars.bformubankav2_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );

    formVars.bformubankav2_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformubankav2_526476_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null
    );

    stateVars.dataSource_526476 = this.state.sbProjeTuruTRENList;
    stateVars.dataSource_526476 = this.state.sbProjeTuruTRENList;
    formVars.bformubankav2_633679_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );

    formVars.bformubankav2_245794_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].bolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].bolum
        : null
    );

    formVars.bformubankav2_730982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinKanali
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinKanali
        : null
    );

    formVars.bformubankav2_622154_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi2
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi2
        : null
    );

    formVars.bformubankav2_877384_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi2
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi2
        : null
    );

    formVars.bformubankav2_628827_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyon
        : null
    );

    formVars.bformubankav2_918664_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmButce
        : null
    );

    formVars.bformubankav2_489574_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimAlani
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimAlani
        : null
    );

    formVars.bformubankav2_980108_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].oyunButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].oyunButce
        : null
    );

    formVars.bformubankav2_98955_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tiyatroSalonAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tiyatroSalonAd
        : null
    );

    formVars.bformubankav2_501496_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].gosterimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].gosterimTarihi
        : null
    );

    formVars.bformubankav2_353342_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].terminTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].terminTarihi
        : null
    );

    formVars.bformubankav2_269440_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].acil
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].acil
        : null
    );

    formVars.bformubankav2_852944_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].briefAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].briefAciklama
        : null
    );

    formVars.bformubankav2_417431_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].isMuzikSecimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].isMuzikSecimi
        : null
    );

    stateVars.isComp726503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873862Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformubankav2_306900_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].bolgeIDTEMP
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].bolgeIDTEMP
        : null
    );

    formVars.bformubankav2_38854_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimAlani
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimAlani
        : null
    );

    stateVars.isComp301339Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorT?.length > 0
            ? stateVars.spMecralarSelectedColorT[0].id
            : this.state.spMecralarSelectedColorT?.length > 0
            ? this.state.spMecralarSelectedColorT[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp654727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorR?.length > 0
            ? stateVars.spMecralarSelectedColorR[0].id
            : this.state.spMecralarSelectedColorR?.length > 0
            ? this.state.spMecralarSelectedColorR[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp772542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp597446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp123993Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "info"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_577170 = this.state.spGetListKullanimByBFId;
    formVars.bformubankav2_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );

    stateVars.isComp169377Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "info"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp714077Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp399400Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "info"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449295Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BFormuBankaV2Component_236080_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_526476_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      ProjeTuruRefKod_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      MKod_2: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_2: ReactSystemFunctions.convertToTypeByName("R", "string"),
      MKod_3: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_3: ReactSystemFunctions.convertToTypeByName("O", "string"),
      MKod_4: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_4: ReactSystemFunctions.convertToTypeByName("D", "string"),
      MKod_5: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_5: ReactSystemFunctions.convertToTypeByName("T", "string"),
      MKod_6: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_6: ReactSystemFunctions.convertToTypeByName("V", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_526476_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbBolgeTRENList = result?.data.sbBolgeTRENList;

    formVars.bformubankav2_306900_options = stateVars.sbBolgeTRENList;
    stateVars.sbKullanimTuruTRENListByRefKod = result?.data.sbKullanimTuruTRENListByRefKod;

    formVars.bformubankav2_299989_options = stateVars.sbKullanimTuruTRENListByRefKod;
    stateVars.spMecralarSelectedColorR = result?.data.spMecralarSelectedColorR;

    formVars.bformubankav2_693646_options = stateVars.spMecralarSelectedColorR;

    stateVars.isComp654727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorR?.length > 0
            ? stateVars.spMecralarSelectedColorR[0].id
            : this.state.spMecralarSelectedColorR?.length > 0
            ? this.state.spMecralarSelectedColorR[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spMecralarSelectedColorO = result?.data.spMecralarSelectedColorO;

    formVars.bformubankav2_55923_options = stateVars.spMecralarSelectedColorO;

    stateVars.isComp772542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp597446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spMecralarSelectedColorD = result?.data.spMecralarSelectedColorD;

    formVars.bformubankav2_38854_options = stateVars.spMecralarSelectedColorD;

    stateVars.spMecralarSelectedColorT = result?.data.spMecralarSelectedColorT;

    formVars.bformubankav2_632783_options = stateVars.spMecralarSelectedColorT;

    stateVars.isComp301339Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorT?.length > 0
            ? stateVars.spMecralarSelectedColorT[0].id
            : this.state.spMecralarSelectedColorT?.length > 0
            ? this.state.spMecralarSelectedColorT[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spMecralarSelectedColorV = result?.data.spMecralarSelectedColorV;

    formVars.bformubankav2_428431_options = stateVars.spMecralarSelectedColorV;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_299989_value", "string"),
      null,
      null,
      "Id"
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp283848Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp283848Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp367103Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp367103Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp665291Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp496054Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp108743Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp629685Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp136378Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange7_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "03"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp665291Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "09"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp665291Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "07"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp496054Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "05"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp108743Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "02"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp629685Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "08"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp136378Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp496960Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange14_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "008"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp496960Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_670571_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_778304_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_458889_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_814839_value", "string"),
      null,
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange16_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorT?.length > 0
            ? stateVars.spMecralarSelectedColorT[0].id
            : this.state.spMecralarSelectedColorT?.length > 0
            ? this.state.spMecralarSelectedColorT[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp301339Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorR?.length > 0
            ? stateVars.spMecralarSelectedColorR[0].id
            : this.state.spMecralarSelectedColorR?.length > 0
            ? this.state.spMecralarSelectedColorR[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp654727Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp772542Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorD?.length > 0
            ? stateVars.spMecralarSelectedColorD[0].id
            : this.state.spMecralarSelectedColorD?.length > 0
            ? this.state.spMecralarSelectedColorD[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp597446Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_526476_onChange20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_526476_onChange20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp726503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873862Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_306900 = this.state.sbBolgeTRENList;
    stateVars.dataSource_306900 = this.state.sbBolgeTRENList;

    stateVars.dataSource_299989 = this.state.sbKullanimTuruTRENListByRefKod;
    stateVars.dataSource_299989 = this.state.sbKullanimTuruTRENListByRefKod;

    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;
    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;

    stateVars.dataSource_428431 = this.state.spMecralarSelectedColorV;
    stateVars.dataSource_428431 = this.state.spMecralarSelectedColorV;

    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;
    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;

    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;
    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;

    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;
    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;

    stateVars.dataSource_658984 = this.state.spMecralarSelectedColorT;

    stateVars.dataSource_673720 = this.state.spMecralarSelectedColorR;

    stateVars.dataSource_381512 = this.state.spMecralarSelectedColorO;

    stateVars.dataSource_953668 = this.state.spMecralarSelectedColorD;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_417431_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp304820Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp541034Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp304820Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp541034Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp298517Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp298517Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp315959Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp315959Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp248524Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp248524Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_315959_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_248524_value", "string"),
      null,
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp297583Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_417431_onChange12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuBankaV2Component_417431_onChange12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp297583Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuBankaV2Component_632783_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_670571_value", "string"),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "bformubankav2_632783_value", "value", "spMecralarSelectedColorT", "id", "id")
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_693646_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_778304_value", "string"),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "bformubankav2_693646_value", "value", "spMecralarSelectedColorR", "id", "id")
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_55923_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_458889_value", "string"),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "bformubankav2_55923_value", "value", "spMecralarSelectedColorO", "id", "id")
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_38854_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_814839_value", "string"),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "bformubankav2_38854_value", "value", "spMecralarSelectedColorD", "id", "id")
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_879715_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_658984_value", "kod"),
        "number"
      ),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("T", "string"),
      SelectedRowId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_658984_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_879715_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorT = result?.data.spMecralarSelectedColorT;

    formVars.bformubankav2_632783_options = stateVars.spMecralarSelectedColorT;

    stateVars.isComp301339Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorT?.length > 0
            ? stateVars.spMecralarSelectedColorT[0].id
            : this.state.spMecralarSelectedColorT?.length > 0
            ? this.state.spMecralarSelectedColorT[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getSelectedRowId = result?.data.getSelectedRowId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_879715_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_879715_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_670571_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getSelectedRowId?.length > 0
          ? stateVars.getSelectedRowId[0].selectedRowId
          : this.state.getSelectedRowId?.length > 0
          ? this.state.getSelectedRowId[0].selectedRowId
          : null
      ),
      null
    );

    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;
    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;

    stateVars.dataSource_658984 = this.state.spMecralarSelectedColorT;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_237302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("T", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_237302_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorT = result?.data.spMecralarSelectedColorT;

    formVars.bformubankav2_632783_options = stateVars.spMecralarSelectedColorT;

    stateVars.isComp301339Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorT?.length > 0
            ? stateVars.spMecralarSelectedColorT[0].id
            : this.state.spMecralarSelectedColorT?.length > 0
            ? this.state.spMecralarSelectedColorT[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_237302_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_237302_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_670571_value", "string"),
      null,
      null
    );

    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;
    stateVars.dataSource_632783 = this.state.spMecralarSelectedColorT;

    stateVars.dataSource_658984 = this.state.spMecralarSelectedColorT;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_961062_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_673720_value", "kod"),
        "number"
      ),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("R", "string"),
      SelectedRowId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_673720_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_961062_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorR = result?.data.spMecralarSelectedColorR;

    formVars.bformubankav2_693646_options = stateVars.spMecralarSelectedColorR;

    stateVars.isComp654727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorR?.length > 0
            ? stateVars.spMecralarSelectedColorR[0].id
            : this.state.spMecralarSelectedColorR?.length > 0
            ? this.state.spMecralarSelectedColorR[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getSelectedRowId = result?.data.getSelectedRowId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_961062_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_961062_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_778304_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getSelectedRowId?.length > 0
          ? stateVars.getSelectedRowId[0].selectedRowId
          : this.state.getSelectedRowId?.length > 0
          ? this.state.getSelectedRowId[0].selectedRowId
          : null
      ),
      null
    );

    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;
    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;

    stateVars.dataSource_673720 = this.state.spMecralarSelectedColorR;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_979065_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("R", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_979065_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorR = result?.data.spMecralarSelectedColorR;

    formVars.bformubankav2_693646_options = stateVars.spMecralarSelectedColorR;

    stateVars.isComp654727Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorR?.length > 0
            ? stateVars.spMecralarSelectedColorR[0].id
            : this.state.spMecralarSelectedColorR?.length > 0
            ? this.state.spMecralarSelectedColorR[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_979065_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_979065_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_778304_value", "string"),
      null,
      null
    );

    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;
    stateVars.dataSource_693646 = this.state.spMecralarSelectedColorR;

    stateVars.dataSource_673720 = this.state.spMecralarSelectedColorR;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_625323_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_381512_value", "kod"),
        "number"
      ),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("O", "string"),
      SelectedRowId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_381512_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_625323_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorO = result?.data.spMecralarSelectedColorO;

    formVars.bformubankav2_55923_options = stateVars.spMecralarSelectedColorO;

    stateVars.isComp772542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp597446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getSelectedRowId = result?.data.getSelectedRowId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_625323_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_625323_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_458889_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getSelectedRowId?.length > 0
          ? stateVars.getSelectedRowId[0].selectedRowId
          : this.state.getSelectedRowId?.length > 0
          ? this.state.getSelectedRowId[0].selectedRowId
          : null
      ),
      null
    );

    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;
    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;

    stateVars.dataSource_381512 = this.state.spMecralarSelectedColorO;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_866836_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("O", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_866836_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorO = result?.data.spMecralarSelectedColorO;

    formVars.bformubankav2_55923_options = stateVars.spMecralarSelectedColorO;

    stateVars.isComp772542Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp597446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spMecralarSelectedColorO?.length > 0
            ? stateVars.spMecralarSelectedColorO[0].id
            : this.state.spMecralarSelectedColorO?.length > 0
            ? this.state.spMecralarSelectedColorO[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_866836_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_866836_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_458889_value", "string"),
      null,
      null
    );

    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;
    stateVars.dataSource_55923 = this.state.spMecralarSelectedColorO;

    stateVars.dataSource_381512 = this.state.spMecralarSelectedColorO;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_303288_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_953668_value", "kod"),
        "number"
      ),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("D", "string"),
      SelectedRowId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_953668_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_303288_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorD = result?.data.spMecralarSelectedColorD;

    formVars.bformubankav2_38854_options = stateVars.spMecralarSelectedColorD;

    stateVars.getSelectedRowId = result?.data.getSelectedRowId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_303288_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_303288_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_814839_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getSelectedRowId?.length > 0
          ? stateVars.getSelectedRowId[0].selectedRowId
          : this.state.getSelectedRowId?.length > 0
          ? this.state.getSelectedRowId[0].selectedRowId
          : null
      ),
      null
    );

    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;
    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;

    stateVars.dataSource_953668 = this.state.spMecralarSelectedColorD;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_154593_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MKod_0: ReactSystemFunctions.convertToTypeByName("0", "number"),
      ProjeTuruRefKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_526476_value",
            "value",
            "sbProjeTuruTRENList",
            "id",
            "projeTuruRefKod"
          )
        ),
        "string"
      ),
      RefKod_0: ReactSystemFunctions.convertToTypeByName("D", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_154593_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spMecralarSelectedColorD = result?.data.spMecralarSelectedColorD;

    formVars.bformubankav2_38854_options = stateVars.spMecralarSelectedColorD;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_154593_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_154593_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_814839_value", "string"),
      null,
      null
    );

    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;
    stateVars.dataSource_38854 = this.state.spMecralarSelectedColorD;

    stateVars.dataSource_953668 = this.state.spMecralarSelectedColorD;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_123993_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_315959_value", "value", "", "", "")
        ),
        null
      ),
      message: "Zorunlu alan!",
      formName: "bformubankav2_315959_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_248524_value", "value", "", "", "")
        ),
        null
      ),
      message: "Zorunlu alan!",
      formName: "bformubankav2_248524_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_632783_value",
            "value",
            "spMecralarSelectedColorT",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_428431_value",
            "value",
            "spMecralarSelectedColorV",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_693646_value",
            "value",
            "spMecralarSelectedColorR",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_55923_value", "value", "spMecralarSelectedColorO", "id", "id")
        ),
        "Guid"
      ),
      prm5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_38854_value", "value", "spMecralarSelectedColorD", "id", "id")
        ),
        "string"
      ),
      secili_0: ReactSystemFunctions.convertToTypeByName("0", "number"),
      chkMuzik0_EserTanim_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_315959_value", "value", "", "", "")
        ),
        "string"
      ),
      chkMuzik1_EserTanim_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_248524_value", "value", "", "", "")
        ),
        "string"
      ),
      chkMuzik_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_123993_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetMecraSecildi = result?.data.spGetMecraSecildi;
    stateVars.spGetEserTanim = result?.data.spGetEserTanim;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "bformubankav2_299989_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_968501_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "")
        ),
        null
      ),
      message: "Lisans Bölgesi Bilgisi Seçilmeli!",
      formName: "bformubankav2_306900_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetEserTanim?.length > 0
            ? stateVars.spGetEserTanim[0].eserTanim
            : this.state.spGetEserTanim?.length > 0
            ? this.state.spGetEserTanim[0].eserTanim
            : null
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_770932_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_707397_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "id")
        ),
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_632783_value",
            "value",
            "spMecralarSelectedColorT",
            "id",
            "id"
          )
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_632783_value",
            "value",
            "spMecralarSelectedColorT",
            "id",
            "id"
          )
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_123993_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "bformubankav2_299989_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_968501_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "")
        ),
        null
      ),
      message: "Lisans Bölgesi Bilgisi Seçilmeli!",
      formName: "bformubankav2_306900_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetEserTanim?.length > 0
            ? stateVars.spGetEserTanim[0].eserTanim
            : this.state.spGetEserTanim?.length > 0
            ? this.state.spGetEserTanim[0].eserTanim
            : null
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_770932_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_707397_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "id")
        ),
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_428431_value",
            "value",
            "spMecralarSelectedColorV",
            "id",
            "id"
          )
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_428431_value",
            "value",
            "spMecralarSelectedColorV",
            "id",
            "id"
          )
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_123993_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "bformubankav2_299989_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_968501_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "")
        ),
        null
      ),
      message: "Lisans Bölgesi Bilgisi Seçilmeli!",
      formName: "bformubankav2_306900_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetEserTanim?.length > 0
            ? stateVars.spGetEserTanim[0].eserTanim
            : this.state.spGetEserTanim?.length > 0
            ? this.state.spGetEserTanim[0].eserTanim
            : null
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_770932_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_707397_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "id")
        ),
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_693646_value",
            "value",
            "spMecralarSelectedColorR",
            "id",
            "id"
          )
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_693646_value",
            "value",
            "spMecralarSelectedColorR",
            "id",
            "id"
          )
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_123993_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "bformubankav2_299989_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_968501_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "")
        ),
        null
      ),
      message: "Lisans Bölgesi Bilgisi Seçilmeli!",
      formName: "bformubankav2_306900_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetEserTanim?.length > 0
            ? stateVars.spGetEserTanim[0].eserTanim
            : this.state.spGetEserTanim?.length > 0
            ? this.state.spGetEserTanim[0].eserTanim
            : null
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_770932_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_707397_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "id")
        ),
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_55923_value", "value", "spMecralarSelectedColorO", "id", "id")
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_55923_value", "value", "spMecralarSelectedColorO", "id", "id")
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_123993_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            ""
          )
        ),
        null
      ),
      message: "Kullanım Türü Bilgisi Seçilmeli!",
      formName: "bformubankav2_299989_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_968501_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "")
        ),
        null
      ),
      message: "Lisans Bölgesi Bilgisi Seçilmeli!",
      formName: "bformubankav2_306900_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Bilgi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "tanim"
          )
        ),
        "string"
      ),
      MuzikKod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetEserTanim?.length > 0
            ? stateVars.spGetEserTanim[0].eserTanim
            : this.state.spGetEserTanim?.length > 0
            ? this.state.spGetEserTanim[0].eserTanim
            : null
        ),
        "string"
      ),
      SureSaat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_770932_value", "value", "", "", "")
        ),
        "number"
      ),
      SureDakika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_707397_value", "value", "", "", "")
        ),
        "number"
      ),
      SureSaniye_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_968501_value", "value", "", "", "")
        ),
        "number"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_306900_value", "value", "sbBolgeTRENList", "id", "id")
        ),
        "Guid"
      ),
      KullanimTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "bformubankav2_299989_value",
            "value",
            "sbKullanimTuruTRENListByRefKod",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      MecraID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_38854_value", "value", "spMecralarSelectedColorD", "id", "id")
        ),
        "string"
      ),
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_38854_value", "value", "spMecralarSelectedColorD", "id", "id")
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_123993_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKullanimSureleriInsert = result?.data.setKullanimSureleriInsert;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_123993_onClick6_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetListKullanimByBFId = result?.data.spGetListKullanimByBFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_123993_onClick7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_123993_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformubankav2_770932_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "number"),
      null
    );

    stateVars.dataSource_577170 = this.state.spGetListKullanimByBFId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_439359_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ids_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformubankav2_577170_value", "ids"),
        "string"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_439359_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.delKullanimSureleriByIds = result?.data.delKullanimSureleriByIds;
    stateVars.spGetListKullanimByBFId = result?.data.spGetListKullanimByBFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_439359_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_439359_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_577170 = this.state.spGetListKullanimByBFId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_633827_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_714077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_757838_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_269440_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_353342_value", "value", "", "", "")
        ),
        "Date"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_779264_value", "value", "sbFirmaTuruTRENList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_366151_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_633679_value", "value", "", "", "")
        ),
        "string"
      ),
      Bolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_245794_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinKanali_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_730982_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_622154_value", "value", "", "", "")
        ),
        "Date"
      ),
      FilmSayisi2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_877384_value", "value", "", "", "")
        ),
        "string"
      ),
      Versiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_628827_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_918664_value", "value", "", "", "")
        ),
        "string"
      ),
      KullanimAlani_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_489574_value", "value", "", "key", "")
        ),
        "string"
      ),
      OyunButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_980108_value", "value", "", "", "")
        ),
        "string"
      ),
      TiyatroSalonAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_98955_value", "value", "", "", "")
        ),
        "string"
      ),
      GosterimTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_501496_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasvuruYapildi_0: ReactSystemFunctions.convertToTypeByName("True", "boolean"),
      Mecras_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      NewDisplay_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay,
        "string"
      ),
      isMuzikSecimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BriefAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_852944_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_714077_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spBasvuruFormuInsert = result?.data.spBasvuruFormuInsert;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_714077_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_714077_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_0: ReactSystemFunctions.convertToTypeByName("True", "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "Bayi"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_714077_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateServisUcret = result?.data.spUpdateServisUcret;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_714077_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_714077_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_714077_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BFormuBankaV2",
      "BFormuTesekkur",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuBankaV2Component_915207_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformubankav2_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_526476_value", "value", "sbProjeTuruTRENList", "id", "id")
        ),
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      BolgeID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_757838_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_269440_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_353342_value", "value", "", "", "")
        ),
        "Date"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_779264_value", "value", "sbFirmaTuruTRENList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_366151_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_633679_value", "value", "", "", "")
        ),
        "string"
      ),
      Bolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_245794_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinKanali_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_730982_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_622154_value", "value", "", "", "")
        ),
        "Date"
      ),
      FilmSayisi2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_877384_value", "value", "", "", "")
        ),
        "string"
      ),
      Versiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_628827_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_918664_value", "value", "", "", "")
        ),
        "string"
      ),
      KullanimAlani_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_489574_value", "value", "", "key", "")
        ),
        "string"
      ),
      OyunButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_980108_value", "value", "", "", "")
        ),
        "string"
      ),
      TiyatroSalonAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_98955_value", "value", "", "", "")
        ),
        "string"
      ),
      GosterimTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_501496_value", "value", "", "", "")
        ),
        "Date"
      ),
      BasvuruYapildi_0: ReactSystemFunctions.convertToTypeByName("True", "boolean"),
      Mecras_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      NewDisplay_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay,
        "string"
      ),
      isMuzikSecimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_417431_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BriefAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformubankav2_852944_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BFormuBankaV2/BFormuBankaV2Component_915207_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spBasvuruFormuInsert = result?.data.spBasvuruFormuInsert;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuBankaV2Component_915207_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_915207_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_0: ReactSystemFunctions.convertToTypeByName("True", "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "Bayi"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuBankaV2/BFormuBankaV2Component_915207_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateServisUcret = result?.data.spUpdateServisUcret;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_915207_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuBankaV2Component_915207_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuBankaV2Component_915207_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
